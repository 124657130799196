import React from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Helmet from "react-helmet"
import SEO from "../components/ui/seo"

import Layout from "../components/ui/layout"


const useStyles = makeStyles(theme => ({
    stepperContainer: {
        width: "100%",
        marginTop: "5rem",
        padding: "1rem"
    },
    stepper: {
        width: "100%",
        "& .MuiStepIcon-root.MuiStepIcon-active": {
            color: theme.palette.secondary.main
        },
        "& .MuiStepIcon-root.MuiStepIcon-completed": {
            color: "green"
        },
    },
    navigationButton: {
        backgroundColor: theme.palette.secondary.main,
        color: "#fff",
        padding: "1rem 3rem",
        width: "9rem",
        borderRadius: "5rem",
        border: `2px solid ${theme.palette.secondary.main}`,
        height: "3rem",
        "&:hover": {
            backgroundColor: theme.palette.common.jaune
        }

    },
    navigationPrecButton: {
        margin: "2rem",
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: "5rem",
        width: "9rem",
        height: "3rem",
        color: theme.palette.secondary.main
    },
    contentContainer: {

    },
    stepContentContainer: {
        width: "100%",

    },
    paper: {
        width: "95%",
        padding: "1rem"
    },
    iconSelected: {
        width: "5rem",
        height: "4rem",
        fill: theme.palette.primary.main
    },
    iconNoneSelected: {
        width: "5rem",
        height: "4rem",
        fill: theme.palette.secondary.main
    },
}))



const PageEstimation = () => {
    const classes = useStyles()

    return (
        <Layout>
            <Helmet>
                <script src='../jestimmo-iframe.js' type="text/javascript" />
            </Helmet>
            <SEO title="Estimation" description="votre estimation en ligne gratuite , en quelques clics" />
            <Grid item container alignItems="center" classes={{ root: classes.stepperContainer }} direction="column">
                <Paper classes={{ root: classes.paper }}>
                <div id="jst__est"></div>
                </Paper>
            </Grid>
        </Layout>
    )
}

export default PageEstimation